import { Component } from '@angular/core';
import { EaccessAuthService } from '../auth/eaccess-auth.service';
import { ROLE_COMPLIANCE } from '../shared/constants';

@Component({
  selector: 'eaa-can-view-users',
  standalone: true,
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewUsersComponent {
  public canView: boolean | null = null;

  constructor(private authService: EaccessAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasRole(ROLE_COMPLIANCE);
  }
}
