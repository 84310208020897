import { Component } from '@angular/core';
import { CanViewAccountsComponent } from './can-view-accounts.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'eaa-accounts-nav-menu',
  standalone: true,
  imports: [CanViewAccountsComponent, RouterLink],
  template: `
    <eaa-can-view-accounts>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/accounts']"><i class="bi bi-credit-card"></i> ACCOUNTS</a>
      </li>
    </eaa-can-view-accounts>
  `
})
export class AccountsNavMenuComponent {}
