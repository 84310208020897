import { Component, OnInit } from '@angular/core';
import { ActiveMotdsComponent } from './motds/active-motds.component';
import { CanWriteMotdsComponent } from './motds/can-write-motds.component';
import { CanViewAccountsComponent } from './accounts/can-view-accounts.component';
import { CanViewUsersComponent } from './users/can-view-users.component';
import { AccountsService } from './accounts/accounts.service';
import { UsersService } from './users/users.service';
import { Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'eaa-home',
  standalone: true,
  imports: [
    ActiveMotdsComponent,
    AsyncPipe,
    CanViewAccountsComponent,
    CanViewUsersComponent,
    CanWriteMotdsComponent,
    RouterLink
  ],
  template: ` <div class="d-flex flex-column gap-3">
    <div class="d-flex gap-3">
      <!-- Accounts tile -->
      <eaa-can-view-accounts>
        <div class="card" style="min-width: 300px;">
          <div class="card-header">
            <h4 class="card-title m-0">ACCOUNTS</h4>
          </div>
          <div class="card-body">
            @if (accountsCount$ | async; as accountsCount) {
              <strong>{{ accountsCount }}</strong> mappable accounts
            }
          </div>
          <div class="card-footer d-flex justify-content-end">
            <a class="btn btn-outline-primary" [routerLink]="['/accounts']">VIEW</a>
          </div>
        </div>
      </eaa-can-view-accounts>

      <!-- Clients tile-->
      <eaa-can-view-users>
        <div class="card" style="min-width: 300px;">
          <div class="card-header">
            <h4 class="card-title m-0">USERS</h4>
          </div>
          <div class="card-body">
            @if (usersCount$ | async; as usersCount) {
              <strong>{{ usersCount }}</strong> active users
            }
          </div>
          <div class="card-footer d-flex justify-content-end">
            <a class="btn btn-outline-primary" [routerLink]="['/users']">VIEW</a>
          </div>
        </div>
      </eaa-can-view-users>
    </div>

    <!-- Active MOTDs -->
    <eaa-can-write-motds>
      <eaa-active-motds></eaa-active-motds>
    </eaa-can-write-motds>
  </div>`
})
export class HomeComponent implements OnInit {
  public accountsCount$: Observable<number> | null = null;
  public usersCount$: Observable<number> | null = null;

  constructor(
    private accountsService: AccountsService,
    private usersService: UsersService
  ) {}

  public ngOnInit(): void {
    this.accountsCount$ = this.accountsService.countAccountsFromCache$();
    this.usersCount$ = this.usersService.countUsersFromCache$();
  }
}
