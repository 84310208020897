import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { combineLatest, concat, map } from 'rxjs';
import { CigpStaff } from '../cigp-staff/cigp-staff.model';
import { differenceBy } from 'lodash-es';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { CigpStaffAvatarCardComponent } from '../cigp-staff/cigp-staff-avatar-card.component';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'eaa-staff-list-add-dialog',
  standalone: true,
  imports: [CigpStaffAvatarCardComponent, DialogModule, MatProgressSpinnerModule, MultiSelectModule, ToastComponent],
  template: `
    <ejs-dialog
      #dgAddEntitledStaff
      [showCloseIcon]="true"
      width="600px"
      [visible]="false"
      header="ADD ENTITLED STAFF"
      [isModal]="true"
    >
      <ng-template #content>
        <ejs-multiselect
          [dataSource]="canAddStaffs"
          [allowFiltering]="true"
          [ignoreAccent]="true"
          [fields]="{ text: 'displayName', value: 'email' }"
          placeholder="Select staff(s)"
          floatLabelType="Auto"
          [allowObjectBinding]="true"
          (filtering)="onFilteringStaffs($event)"
          [(value)]="staffsToAdd"
        >
          <ng-template #itemTemplate="" let-data="">
            <div class="p-1">
              <eaa-cigp-staff-avatar-card [user]="data" [showName]="true"></eaa-cigp-staff-avatar-card>
            </div>
          </ng-template>
          <ng-template #valueTemplate="" let-data="">
            <eaa-cigp-staff-avatar-card [user]="data" size="18px" [showInitials]="true"></eaa-cigp-staff-avatar-card>
          </ng-template>
        </ejs-multiselect>
      </ng-template>
      <ng-template #footerTemplate>
        <div class="btn-group">
          <button class="btn btn-outline-secondary" (click)="close()">CANCEL</button>
          <button class="btn btn-primary" (click)="saveChanges()">SAVE</button>
        </div>
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastAddStaffAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class StaffListAddDialogComponent implements OnInit {
  public canAddStaffs: CigpStaff[] = [];
  public staffsToAdd: CigpStaff[] = [];
  public processingMessage = '';

  @Output()
  public staffsAdded = new EventEmitter<CigpStaff[]>();

  @ViewChild('dgAddEntitledStaff')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastAddStaffAction')
  public toastAddStaffAction?: ToastComponent;

  constructor(private cigpStaffService: CigpStaffService) {}

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.dialog?.hide();
  }

  public ngOnInit(): void {
    combineLatest([this.cigpStaffService.getStaffsFromCache$(), this.cigpStaffService.getEntitledStaffs$()])
      .pipe(map(([staffs, entitledStaffs]) => differenceBy(staffs, entitledStaffs, 'id')))
      .subscribe((canAddStaffs) => (this.canAddStaffs = canAddStaffs));
  }

  public onFilteringStaffs(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('displayName', 'contains', e.text, true, true)
      .or('email', 'contains', e.text, true, true)
      .or('initials', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.canAddStaffs, query);
  }

  public saveChanges() {
    if (!this.dialog || this.staffsToAdd.length === 0) {
      return;
    }

    this.processingMessage = `Adding ${this.staffsToAdd.length} staff(s).`;
    this.toastAddStaffAction?.show();

    this.cigpStaffService.addStaffsToEntitledList$(this.staffsToAdd).subscribe((entitledStaffs) => {
      this.staffsAdded.emit(entitledStaffs);
      this.close();
      this.resetToast();
    });
  }

  private resetToast() {
    this.toastAddStaffAction?.hide();
    this.processingMessage = '';
  }
}
