import { Component } from '@angular/core';
import { EaccessAuthService } from '../../auth/eaccess-auth.service';
import { ROLE_AUDIT_TRAIL_MONTHLY_STATEMENTS_DISTRIBUTION_READ } from '../../shared/constants';

@Component({
  selector: 'eaa-can-view-audit-trail-monthly-statements-distribution',
  standalone: true,
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewAuditTrailMonthlyStatementsDistributionComponent {
  public canView: boolean | null = null;

  constructor(private authService: EaccessAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasRole(ROLE_AUDIT_TRAIL_MONTHLY_STATEMENTS_DISTRIBUTION_READ);
  }
}
