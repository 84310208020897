import { Component } from '@angular/core';
import { CanViewUsersComponent } from './can-view-users.component';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'eaa-users-nav-menu',
  standalone: true,
  imports: [CanViewUsersComponent, RouterLink],
  template: `
    <eaa-can-view-users>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/users']"><i class="bi bi-people"></i> USERS</a>
      </li>
    </eaa-can-view-users>
  `
})
export class UsersNavMenuComponent {}
