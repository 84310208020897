import { Component, OnInit } from '@angular/core';
import { EaccessAuthService } from '../auth/eaccess-auth.service';
import { ROLE_COMPLIANCE } from '../shared/constants';

@Component({
  selector: 'eaa-can-write-motds',
  standalone: true,
  imports: [],
  template: `
    @if (canWrite) {
      <ng-content></ng-content>
    }
  `
})
export class CanWriteMotdsComponent implements OnInit {
  public canWrite: boolean | null = null;

  constructor(private authService: EaccessAuthService) {}

  public ngOnInit(): void {
    this.canWrite = this.authService.hasRole(ROLE_COMPLIANCE);
  }
}
