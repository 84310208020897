import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AccountsService } from '../accounts/accounts.service';
import { Account } from '../accounts/account.model';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastComponent } from '../shared/toast.component';
import { MappingsService } from '../mappings/mappings.service';
import { UserMappingsResponse } from '../mappings/mapping.model';

interface EditUserMappingsDialogFormConfig {
  accounts: FormControl<Account[] | null>;
}

@Component({
  selector: 'eaa-edit-user-mappings-dialog',
  standalone: true,
  imports: [DialogModule, MatProgressSpinnerModule, MultiSelectModule, ReactiveFormsModule, ToastComponent],
  template: `
    <ejs-dialog #dgMappings [showCloseIcon]="true" [isModal]="true" [visible]="false" width="800px">
      <ng-template #content>
        <form [formGroup]="form">
          <ejs-multiselect
            [dataSource]="accounts"
            [allowFiltering]="true"
            [ignoreAccent]="true"
            [fields]="{ text: 'shortName', value: 'id' }"
            placeholder="Select account(s)"
            formControlName="accounts"
            [allowObjectBinding]="true"
            (filtering)="onFilteringPortfolios($event)"
          >
            <ng-template #itemTemplate="" let-data="">
              <div class="d-flex justify-content-between">
                <div>{{ data.name }}</div>
                <div class="d-flex flex-column align-items-end">
                  <small>{{ data.shortName }}</small>
                </div>
              </div>
            </ng-template>
          </ejs-multiselect>
        </form>
      </ng-template>
      <ng-template #header>Mappings of {{ userDisplayName }} ({{ userEmail }}) </ng-template>
      <ng-template #footerTemplate>
        <div class="btn-group">
          <button class="btn btn-outline-secondary" (click)="close()">CANCEL</button>
          <button class="btn btn-primary" (click)="saveMappingsChanges()">SAVE</button>
        </div>
      </ng-template>
    </ejs-dialog>

    <eaa-toast #toastEditUserMappingsAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </eaa-toast>
  `
})
export class EditUserMappingsDialogComponent implements OnChanges, OnInit {
  public accounts: Account[] | null = null;
  public form: FormGroup = new FormGroup({});
  public processingMessage = '';

  @Input()
  public userDisplayName: string | null | undefined = null;

  @Input()
  public userEmail: string | null | undefined = null;

  @Input()
  public userType: string | null = null;

  @ViewChild('dgMappings')
  public dialog: DialogComponent | null = null;

  @ViewChild('toastEditUserMappingsAction')
  public toastEditUserMappingsAction?: ToastComponent;

  constructor(
    private mappingsService: MappingsService,
    private accountsService: AccountsService,
    private fb: FormBuilder
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['userEmail']?.currentValue !== changes['userEmail']?.previousValue) {
      this.form.disable();
      this.form.reset();

      if (this.userEmail && this.userType) {
        this.mappingsService.getUsersMappedAccountIds$(this.userType, this.userEmail).subscribe((response) => {
          this.form
            .get('accounts')
            ?.setValue(
              response && response.length > 0
                ? this.accounts?.filter((a) => response[0].mappedAccountIds.includes(a.id))
                : []
            );

          this.form.enable();
        });
      }
    }
  }

  public ngOnInit(): void {
    this.accountsService.getAccountsFromCache$().subscribe((accounts) => (this.accounts = accounts));

    this.form = this.fb.group<EditUserMappingsDialogFormConfig>({
      accounts: this.fb.control<Account[]>([])
    });
  }

  public show(): void {
    this.dialog?.show();
  }

  public close(): void {
    this.dialog?.hide();
  }

  public onFilteringPortfolios(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('name', 'contains', e.text, true, true).or(
      'shortName',
      'contains',
      e.text,
      true,
      true
    );

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.accounts, query);
  }

  public saveMappingsChanges() {
    if (!this.userEmail || !this.userType || this.form.invalid) {
      return;
    }

    this.form.disable();

    const selectedAccounts = (this.form.get('accounts')?.value as Account[] | null) || [];

    this.processingMessage = `Saving ${selectedAccounts.length} mappings`;
    this.toastEditUserMappingsAction?.show();

    this.mappingsService
      .upsertUserMappings$({
        accounts: selectedAccounts.map((m) => ({ id: m.id, name: m.name })),
        userEmail: this.userEmail,
        userType: this.userType
      })
      .subscribe({
        next: (response) => this.handleAfterExecute(true, response),
        error: () => this.handleAfterExecute(false)
      });
  }

  private handleAfterExecute(success: boolean, response?: UserMappingsResponse): void {
    if (success && response) {
      this.processingMessage = `Mappings saved.`;

      this.form
        .get('accounts')
        ?.setValue(
          response.mappedAccountIds && response.mappedAccountIds.length > 0
            ? this.accounts?.filter((a) => response.mappedAccountIds.includes(a.id))
            : []
        );

      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.form.enable();
    this.dialog?.hide();
  }

  private resetToast() {
    this.toastEditUserMappingsAction?.hide();
    this.processingMessage = '';
  }
}
