import { Component, OnInit } from '@angular/core';
import {
  AjaxSettingsModel,
  DetailsViewService,
  FileManagerModule,
  NavigationPaneService,
  ToolbarService,
  VirtualizationService
} from '@syncfusion/ej2-angular-filemanager';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, of, switchMap } from 'rxjs';
import { UsersService } from '../users/users.service';
import { Location } from '@angular/common';
import { BasicUser } from '../shared/basic-user.model';
import { EaccessAuthService } from '../auth/eaccess-auth.service';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'eaa-user-documents',
  standalone: true,
  imports: [FileManagerModule],
  providers: [DetailsViewService, NavigationPaneService, ToolbarService, VirtualizationService],
  template: `
    <div class="card">
      <div class="card-header">
        <h4 class="card-title m-0">{{ user?.displayName }} | Documents</h4>
      </div>
      <div class="card-body p-0">
        @if (ajaxSettings) {
          <ejs-filemanager [ajaxSettings]="ajaxSettings" view="Details" [enableVirtualization]="true" height="700px">
          </ejs-filemanager>
        }
      </div>
      <div class="card-footer">
        <button class="btn btn-outline-secondary" (click)="location.back()">BACK</button>
      </div>
    </div>
  `
})
export class UserDocumentsComponent implements OnInit {
  public view?: any;
  public ajaxSettings: AjaxSettingsModel | null = null;
  public user: BasicUser | null = null;

  constructor(
    private authService: EaccessAuthService,
    private route: ActivatedRoute,
    private usersService: UsersService,
    private cigpStaffService: CigpStaffService,
    public location: Location
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) => {
          const id = params.get('id');
          return id && id !== undefined
            ? params.get('type') === 'staff'
              ? this.cigpStaffService.getStaffFromCache$(id)
              : this.usersService.getUserFromCache$(id)
            : of(null);
        })
      ),
      this.authService.getAccessToken$()
    ]).subscribe(([user, token]) => {
      if (user && token) {
        this.user = user;
        this.ajaxSettings = {
          url: `${environment.apiEndpoint}/api/documents/${user.id}/action?access_token=${token}`,
          downloadUrl: `${environment.apiEndpoint}/api/documents/${user.id}/download?access_token=${token}`,
          uploadUrl: `${environment.apiEndpoint}/api/documents/${user.id}/upload?access_token=${token}`
        };
      }
    });
  }
}
