import { Component, OnInit, ViewChild } from '@angular/core';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';
import { Observable } from 'rxjs';
import { CigpStaff } from '../cigp-staff/cigp-staff.model';
import {
  EditService,
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { Router } from '@angular/router';
import {
  ID_EDIT_MAPPINGS,
  ID_GO_TO_DOCUMENTS,
  ID_GO_TO_MESSAGES,
  TOOLBAR_ITEM_DOCUMENTS,
  TOOLBAR_ITEM_MAPPINGS,
  TOOLBAR_ITEM_MESSAGES,
  USER_TYPE_STAFF
} from '../shared/constants';
import { EditUserMappingsDialogComponent } from './edit-user-mappings-dialog.component';
import { CigpStaffAvatarCardComponent } from '../cigp-staff/cigp-staff-avatar-card.component';
import { Dialog, DialogModule, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { StaffListAddDialogComponent } from './staff-list-add-dialog.component';

interface CigpStaffWithMappingsCount extends CigpStaff {
  mappingsCount?: number;
}

@Component({
  selector: 'eaa-staff-list',
  standalone: true,
  imports: [
    CigpStaffAvatarCardComponent,
    DialogModule,
    DropDownListModule,
    EditUserMappingsDialogComponent,
    GridModule,
    StaffListAddDialogComponent
  ],
  providers: [EditService, ExcelExportService, FilterService, PageService, SearchService, ToolbarService],
  template: `
    <ejs-grid
      #grdStaffs
      id="grdStaffs"
      [dataSource]="staffs"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
      [allowFiltering]="true"
      [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
      [allowExcelExport]="true"
      [allowTextWrap]="true"
      [toolbar]="toolbar"
      [editSettings]="{
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
        showDeleteConfirmDialog: true
      }"
      (toolbarClick)="toolbarClick($event)"
      (rowSelected)="onRowSelected($event)"
      (rowDeselected)="onRowSelected($event)"
    >
      <e-columns>
        <e-column field="id" headerText="#" [isPrimaryKey]="true" [visible]="false"></e-column>
        <e-column field="displayName" headerText="Name">
          <ng-template #template let-data>
            <eaa-cigp-staff-avatar-card [user]="data" [showName]="true"></eaa-cigp-staff-avatar-card>
          </ng-template>
        </e-column>
        <e-column field="initials" headerText="Initials"></e-column>
        <e-column field="email" headerText="Email"></e-column>
        <e-column field="mappingsCount" headerText="Mappings" type="number"></e-column>
      </e-columns>
    </ejs-grid>

    <eaa-edit-user-mappings-dialog
      #dgStaffMappings
      [userDisplayName]="selectedUser?.displayName"
      [userEmail]="selectedUser?.email"
      [userType]="userType"
    ></eaa-edit-user-mappings-dialog>

    <eaa-staff-list-add-dialog #dgAddEntitledStaff (staffsAdded)="onStaffsAdded($event)"></eaa-staff-list-add-dialog>
  `
})
export class StaffListComponent implements OnInit {
  public staffs: CigpStaffWithMappingsCount[] | null = null;
  public userType = USER_TYPE_STAFF;
  public toolbar = [
    'Add',
    'Delete',
    TOOLBAR_ITEM_DOCUMENTS,
    TOOLBAR_ITEM_MESSAGES,
    TOOLBAR_ITEM_MAPPINGS,
    'ExcelExport',
    'Search'
  ];
  private deleteDialog: Dialog | null = null;

  @ViewChild('grdStaffs')
  public grid?: GridComponent;

  @ViewChild('dgStaffMappings')
  public editStaffMappingsDialog: EditUserMappingsDialogComponent | null = null;

  @ViewChild('dgAddEntitledStaff')
  public staffListAddDialog: StaffListAddDialogComponent | null = null;

  constructor(
    private cigpStaffService: CigpStaffService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.cigpStaffService.getEntitledStaffs$().subscribe((staffs) => {
      this.staffs = staffs;
      this.countMappings();
    });

    this.cigpStaffService.canEditEntitledStaffsList$().subscribe((canEdit) => {
      if (this.grid) {
        this.grid.editSettings.allowAdding = canEdit;
        this.grid.editSettings.allowDeleting = canEdit;
        this.grid?.enableToolbarItems(['grdStaffs_delete'], false);
      }
    });
  }

  public onStaffsAdded(staffs: CigpStaff[]): void {
    this.staffs = staffs;
    this.countMappings();
  }

  public toolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grdStaffs_add') {
      args.cancel = true;
      this.staffListAddDialog?.show();
    } else if (args.item.id === 'grdStaffs_delete') {
      args.cancel = true;

      const staff = this.grid?.getSelectedRecords()[0] as CigpStaff;

      if (staff) {
        this.deleteDialog = DialogUtility.confirm({
          title: 'REMOVE STAFF',
          content: `Remove ${staff.displayName} from the entitled staff list?`,
          okButton: {
            text: 'OK',
            click: () => {
              this.deleteDialog?.hide();
              this.cigpStaffService.removeStaffFromEntitledList$(staff.id).subscribe((staffs) => {
                this.staffs = staffs;
                this.countMappings();
              });
            }
          },
          cancelButton: { text: 'Cancel', click: () => this.deleteDialog?.hide() },
          showCloseIcon: true,
          closeOnEscape: true,
          position: { X: 'center', Y: 'center' }
        });
      }
    } else if (args.item.id === 'grdStaffs_excelexport') {
      this.grid?.excelExport();
    } else if (args.item.id === ID_EDIT_MAPPINGS) {
      this.editStaffMappingsDialog?.show();
    } else if (args.item.id === ID_GO_TO_DOCUMENTS) {
      this.router.navigate(['/users', 'staff', (this.grid?.getSelectedRecords()[0] as CigpStaff).id, 'documents']);
    } else if (args.item.id === ID_GO_TO_MESSAGES) {
      this.router.navigate(['/users', 'staff', (this.grid?.getSelectedRecords()[0] as CigpStaff).id, 'messages']);
    }
  }

  public onRowSelected(args: any): void {
    if (args.data) {
      this.grid?.enableToolbarItems(
        [ID_EDIT_MAPPINGS, ID_GO_TO_DOCUMENTS, ID_GO_TO_MESSAGES],
        args.name === 'rowSelected'
      );

      this.grid?.enableToolbarItems(['grdStaffs_delete'], args.data['mappingsCount'] === 0);
    }
  }

  public get selectedUser(): CigpStaff | null {
    return this.grid?.getSelectedRecords()[0] as CigpStaff;
  }

  private countMappings(): void {
    this.cigpStaffService.countMappingsByStaff$().subscribe((mappingsCount) => {
      if (this.staffs && this.staffs.length > 0) {
        this.staffs = this.staffs.map((staff) => ({
          ...staff,
          mappingsCount: mappingsCount.find((m) => m.email === staff.email)?.mappingsCount
        }));
      }
    });
  }
}
