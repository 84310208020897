import { Component } from '@angular/core';

@Component({
  selector: 'eaa-account-details',
  standalone: true,
  imports: [],
  template: `
    <p>
      account-details works!
    </p>
  `,
  styles: ``
})
export class AccountDetailsComponent {

}
