import { Component } from '@angular/core';
import { CanViewAuditTrailComponent } from './can-view-audit-trail.component';
import { RouterLink } from '@angular/router';
import { CanViewAuditTrailMonthlyStatementsDistributionComponent } from './monthly-statements-distribution/can-view-audit-trail-monthly-statements-distribution.component';

@Component({
  selector: 'eaa-audit-trail-nav-menu',
  standalone: true,
  imports: [CanViewAuditTrailComponent, CanViewAuditTrailMonthlyStatementsDistributionComponent, RouterLink],
  template: `
    <eaa-can-view-audit-trail>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="bi bi-journal" aria-hidden="true"></span> AUDIT TRAIL
        </a>
        <ul class="dropdown-menu">
          <eaa-can-view-audit-trail-monthly-statements-distribution>
            <li>
              <a class="dropdown-item" [routerLink]="['/audit-trail', 'monthly-statements-distribution']"
                >MONTHLY STATEMENTS DISTRIBUTION</a
              >
            </li>
          </eaa-can-view-audit-trail-monthly-statements-distribution>
        </ul>
      </li>
    </eaa-can-view-audit-trail>
  `
})
export class AuditTrailNavMenuComponent {}
