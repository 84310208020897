import { Component, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EaccessAuthService } from '../auth/eaccess-auth.service';
import { AccountsService } from '../accounts/accounts.service';
import { NGXLogger } from 'ngx-logger';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, map, tap } from 'rxjs';
import { NavStateService } from './nav-state.service';
import { UsersService } from '../users/users.service';
import { CigpStaffService } from '../cigp-staff/cigp-staff.service';

@Component({
  selector: 'eaa-landing',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: `
    @if (!authService.loggedIn) {
      <div class="d-flex justify-content-center align-items-center gap-4">
        <button class="btn btn-outline-primary" (click)="login()">PLEASE LOG IN</button>
      </div>
    } @else {
      <div class="d-flex flex-column gap-4">
        <div class="d-flex justify-content-center align-items-center gap-3">
          <p class="m-0"><i> Loading accounts...</i></p>
          @if (loadingAccounts) {
            <mat-spinner diameter="20"></mat-spinner>
          } @else {
            <i class="bi bi-check-lg text-success"></i>
          }
        </div>
        <div class="d-flex justify-content-center align-items-center gap-3">
          <p class="m-0"><i> Loading users...</i></p>
          @if (loadingUsers) {
            <mat-spinner diameter="20"></mat-spinner>
          } @else {
            <i class="bi bi-check-lg text-success"></i>
          }
        </div>
        <div class="d-flex justify-content-center align-items-center gap-3">
          <p class="m-0"><i> Loading staffs...</i></p>
          @if (loadingStaffs) {
            <mat-spinner diameter="20"></mat-spinner>
          } @else {
            <i class="bi bi-check-lg text-success"></i>
          }
        </div>
      </div>
    }
  `
})
export class LandingComponent implements OnInit {
  public loadingAccounts = true;
  public loadingStaffs = true;
  public loadingUsers = true;

  constructor(
    public authService: EaccessAuthService,
    private router: Router,
    private accountsService: AccountsService,
    private usersService: UsersService,
    private cigpStaffService: CigpStaffService,
    private navStateService: NavStateService,
    private logger: NGXLogger,
    private route: ActivatedRoute
  ) {}

  public ngOnInit(): void {
    if (this.authService.loggedIn) {
      combineLatest([
        this.accountsService.accountsSet$.pipe(tap((accountsSet) => (this.loadingAccounts = !accountsSet))),
        this.usersService.usersSet$.pipe(tap((usersSet) => (this.loadingUsers = !usersSet))),
        this.cigpStaffService.staffsSet$.pipe(tap((staffsSet) => (this.loadingStaffs = !staffsSet)))
      ])
        .pipe(map(([accountsSet, usersSet, staffsSet]) => accountsSet && usersSet && staffsSet))
        .subscribe((ready) => {
          if (ready) {
            if (this.navStateService.isFirstLoad) {
              this.navStateService.isFirstLoad = false;
              this.logger.log('System is now ready', this.route);
              setTimeout(() => {
                this.router.navigate([this.route.snapshot.queryParamMap.get('origin') || '/home']);
              }, 250);
            }
          }
        });
    }
  }

  public login(): void {
    this.authService.login$().subscribe();
  }
}
